import { useSessionStore } from "@/stores/session";
import { useWatchStore } from "@/stores/watch";
import { useGTMStore } from "@/stores/gtm";
import { toRaw } from "vue";
import { GTM_LOGIN } from "@/constants";

export default function () {
  const gtm = useGtm();
  const globalConfig = () => {
    const nuxtApp = useNuxtApp();
    const sessionStore = useSessionStore();
    return {
      user_id: sessionStore.househould_id || "",
      language_cd: nuxtApp.$i18n.locale.value === "ar" ? "arabic" : "english",
      login_status: sessionStore.isAuthenticated ? "login" : "guest",
    };
  };

  const audienceType = () => {
    const isKidsMode = useIsKidsMode();
    const sessionStore = useSessionStore();
    const gtmStore = useGTMStore();
    return isKidsMode.value ||
      sessionStore?.activeProfile?.isKid ||
      gtmStore.isKid
      ? "kids"
      : "adults";
  };

  const progressContentType = (media) => {
    return media?.contentType;
  };
  const progressType = (media) => {
    return media?.type;
  };
  const progressGenres = () => {
    const store = useWatchStore();
    let genres = "";
    const genreEntityList = progressGenreList(store.media);
    if (genreEntityList?.length > 0) {
      genres = toRaw(genreEntityList)
        .map((g) => {
          return g.name;
        })
        .join("/");
    }
    return genres;
  };

  const progressGenreList = (media) => {
    const type = progressType(media);
    let list = [];
    switch (type) {
      case "Movie":
        list = media.aggregatedVideo?.genreEntityList || media.genreEntityList;
        break;
      case "Season":
        const season = media?.tvShowSeason || media?.tvShowSeasons?.[0];
        list = season?.genreEntityList || season?.genres;
        break;
      case "Series":
        list = media?.tvShowSeries?.genres;
        if (progressContentType(media) == "Podcast") {
          list = media.genreEntityList;
        }
        break;
      default:
        list = media?.genreEntityList;
        break;
    }
    const output = list?.filter((l) => {
      const isNotMajidSong = l?.externalId != "Majid Songs";
      const isNotBlank = l.name.trim().length > 0;

      return isNotMajidSong && isNotBlank;
    });
    return output;
  };

  const whichTypeNeedDispatch = (fullPath, media) => {
    if (!fullPath) {
      return;
    }
    const isKidsMode = useIsKidsMode();
    const sessionStore = useSessionStore();
    const watchStore = useWatchStore();
    let landingPage = "home";
    const isKid = isKidsMode.value || sessionStore?.activeProfile?.isKid;

    if (isKid) {
      if (fullPath.indexOf("programs") >= 0) {
        landingPage = "programs";
      } else if (fullPath.indexOf("characters") >= 0) {
        landingPage = "characters";
      } else if (fullPath.indexOf("songs") >= 0) {
        landingPage = "songs";
      } else {
      }
    } else {
      if (media.isPodcast) {
        if (media.type == "Movie" || media.aggregatedVideo) {
          showsRadioStart(landingPage, media, "Movie");
        } else {
          showsRadioStart(landingPage, media, "Series");
        }
      } else {
        if (fullPath.indexOf("shows") >= 0) {
          landingPage = "shows";
          showsVideoStart(landingPage, fullPath, media);
        } else if (fullPath.indexOf("movies") >= 0) {
          landingPage = "movies";
          moviesVideoStart(landingPage, fullPath, media);
        } else if (fullPath.indexOf("sports") >= 0) {
          landingPage = "sports";
          showsVideoStart(landingPage, fullPath, media);
        } else if (fullPath.indexOf("radio") >= 0) {
          landingPage = "radio";
          showsVideoStart(landingPage, fullPath, media);
        } else if (fullPath.indexOf("news") >= 0) {
          landingPage = "news";
          showsVideoStart(landingPage, fullPath, media);
        } else {
          if (
            watchStore?.media?.type == "Movie" ||
            !watchStore?.currentEpisode
          ) {
            moviesVideoStart(landingPage, fullPath, media);
          } else {
            showsVideoStart(landingPage, fullPath, media);
          }
        }
      }
    }
  };

  const showsVideoStart = (fullPath, media) => {
    const store = useWatchStore();
    const gtmStore = useGTMStore();
    let genres = progressGenres();
    const gtm = useGtm();

    gtm.trackEvent({
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "video_start",
      audience_type: audienceType(),
      media_type: gtmStore.mediaType,
      media_name:
        store?.currentEpisode?.displayName || store?.currentEpisode?.name,
      season: store.currentEpisode?.tvShowReference?.seasonNumber,
      episode: store.currentEpisode?.tvShowReference?.episodeNumber,
      genres: genres,
      show_id: store.currentEpisode?.tvShowReference?.seriesExternalId,
      season_id: store.currentEpisode?.tvShowReference?.seasonExternalId,
      episode_id: store?.currentEpisode?.assetExternalId,
      list_name: gtmStore.listName,
    });
  };

  const showsRadioStart = (fullPath, media, podcastType) => {
    const store = useWatchStore();
    const gtmStore = useGTMStore();
    let genres = progressGenres();
    const gtm = useGtm();

    if (podcastType == "Movie") {
      gtm.trackEvent({
        ...globalConfig(),
        event: "eventTracker",
        custom_event_name: "video_start",
        audience_type: audienceType(),
        media_type: gtmStore.mediaType,
        media_name: media?.aggregatedVideo?.name || media.name,
        genres: genres,
        //if applicable
        movie_id: media?.aggregatedVideo?.assetExternalId,
        // list_name: "<hero banner/latest releases>",
      });
    } else {
      gtm.trackEvent({
        ...globalConfig(),
        event: "eventTracker",
        custom_event_name: "video_start",
        audience_type: audienceType(),
        media_type: gtmStore.mediaType,
        media_name: media.displayName || media.name,
        season: media?.tvShowReference?.seasonNumber,
        episode: media?.tvShowReference?.episodeNumber,
        genres: genres,
        show_id: media?.tvShowReference?.seriesExternalId,
        season_id: media?.tvShowReference?.seasonExternalId,
        episode_id: media?.assetExternalId,
        list_name: "",
      });
    }
  };

  const moviesVideoStart = (type, fullPath, media) => {
    const gtmStore = useGTMStore();
    let genres = progressGenres();
    const gtm = useGtm();

    gtm.trackEvent({
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "video_start",
      audience_type: audienceType(),
      media_type: gtmStore.mediaType,
      media_name: media?.aggregatedVideo?.name || media.name,
      genres: genres,
      //if applicable
      movie_id: media?.aggregatedVideo?.assetExternalId,
      list_name: gtmStore.listName,
    });
  };

  const liveShowVideoStart = (config) => {
    const gtm = useGtm();

    gtm.trackEvent({
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "watch_live_show",
      audience_type: audienceType(),
      tv_channel_type: config?.isLive ? "live" : "recorded",
      tv_channel_name: config?.channel?.name,
      tv_show_name: config?.program?.name,
      //if applicable
      tv_channel_id: config?.channel?.externalChannelId,
    });
  };

  const FMVideoStart = (media, isLive, program) => {
    const gtmStore = useGTMStore();
    const gtm = useGtm();

    let config = {
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "fm_start",
      audience_type: audienceType(),
      media_type: gtmStore.mediaType,
      fm_channel: media.name,
      fm_program_type: isLive ? "live" : "recorded",
      fm_channel_id: media.externalChannelId,
    };
    if (!isLive && program) {
      config.fm_program_name = program?.name;
    }
    gtm.trackEvent(config);
  };

  const videoInterations = (interaction_type, media) => {
    const gtmStore = useGTMStore();
    const store = useWatchStore();
    const gtm = useGtm();

    let genres = "";

    gtm.trackEvent({
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "video_interaction",
      interaction_type: interaction_type,
      audience_type: audienceType(),
      media_type: gtmStore.mediaType,
      media_name: media.media_name,
      genres: genres,
      list_name: gtmStore.listName,
    });
  };

  const seriesInterations = (interaction_type, media) => {
    const gtmStore = useGTMStore();

    let genres = progressGenres();
    const gtm = useGtm();

    gtm.trackEvent({
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "video_interaction",
      interaction_type: interaction_type,
      audience_type: audienceType(),
      media_type: gtmStore.mediaType,
      media_name: media.media_name,
      season: media.season,
      episode: media.episode,
      //if applicable
      show_id: media.show_id,
      //if applicable
      season_id: media.season_id,
      //if applicable
      episode_id: media.episode_id,
      list_name: gtmStore.listName,
      genres: genres,
    });
  };

  const loginSuccess = (method) => {
    const nuxtApp = useNuxtApp();
    const gtm = useGtm();

    gtm.trackEvent({
      ...globalConfig(),
      event: "eventTracker",
      custom_event_name: "login",
      method: method,
      login_status: GTM_LOGIN.LOGGED_IN,
      language_cd: nuxtApp.$i18n.locale.value === "ar" ? "arabic" : "english",
    });
  };

  const signupSuccess = (method, account) => {
    const nuxtApp = useNuxtApp();
    const gtm = useGtm();

    gtm &&
      gtm.trackEvent({
        event: "eventTracker",
        custom_event_name: "sign_up",
        method: method,
        login_status: GTM_LOGIN.LOGGED_IN,
        language_cd: nuxtApp.$i18n.locale.value === "ar" ? "arabic" : "english",
        user_id: account,
      });
  };

  const firstFiveSecond = (type, media, fullPath) => {
    const gtmStore = useGTMStore();
    const store = useWatchStore();
    const gtm = useGtm();

    let genres = progressGenres();

    if (useMediaType(store.media).isMovie || !store.currentEpisode) {
      gtm.trackEvent({
        ...globalConfig(),
        event: "eventTracker",
        custom_event_name: "video_milestone_5second",
        audience_type: audienceType(),
        media_type: gtmStore.mediaType,
        media_name: media?.aggregatedVideo?.name || media?.name,
        genres: genres,
        //if applicable
        movie_id: media?.aggregatedVideo?.assetExternalId,
        list_name: gtmStore.listName,
      });
    } else {
      gtm.trackEvent({
        ...globalConfig(),
        event: "eventTracker",
        custom_event_name: "video_milestone_5second",
        audience_type: audienceType(),
        media_type: gtmStore.mediaType,
        media_name:
          store?.currentEpisode?.displayName || store?.currentEpisode?.name,
        season: store.currentEpisode?.tvShowReference?.seasonNumber,
        episode: store.currentEpisode?.tvShowReference?.episodeNumber,
        show_id: store.currentEpisode?.tvShowReference?.seriesExternalId,
        season_id: store.currentEpisode?.tvShowReference?.seasonExternalId,
        episode_id: store?.currentEpisode?.assetExternalId,
        genres: genres,
        list_name: gtmStore.listName,
      });
    }
  };

  return {
    whichTypeNeedDispatch,
    loginSuccess,
    signupSuccess,
    videoInterations,
    seriesInterations,
    firstFiveSecond,
    FMVideoStart,
    liveShowVideoStart,
  };
}
